import * as React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import News from '../components/news';
// import styles from '../components/styles/nyheter.css';

const transition_short = { duration: 0.2, ease: [0.6, 0.01, -0.05, 0.9] };
const transition_long = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

const Arbeid = () => {
	return (
		<Layout pageTitle="Nyheter" background={true}>
			<motion.div
				style={{ backgroundColor: '#fff' }}
				class="header"
				id="nyheter"
			>
				<motion.div>
					<div>
						<div _ngcontent-qps-c63="" class="container">
							<div _ngcontent-qps-c63="" class="hero">
								<h1 _ngcontent-qps-c63="">Arbeid</h1>
								<h2 _ngcontent-qps-c63="">
									Tidligere prosjekter — her kan du lese noen{' '}
									<i>Case Studies</i> fra vellykket
									prosjekter.
								</h2>
							</div>
						</div>
					</div>

					<style jsx>{`
						.blog-featured-posts {
							padding-top: calc(280px * var(--scale));
							padding-bottom: calc(40px * var(--scale));
							margin-top: -14rem;
						}
						@media (max-width: 768px) {
							.blog-featured-posts {
								margin-top: -4rem;
							}
						}
						.block-custom-posts__posts {
							margin: 0 calc(-20px * var(--scale));
							padding: 0;
							list-style: none;
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-ms-flex-wrap: wrap;
							flex-wrap: wrap;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
						}
						.blog-featured-posts__row.first
							ul
							li:last-child:not(:first-child) {
							width: 33%;
						}
						.blog-featured-posts__row.second ul {
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							-webkit-box-pack: start;
							-ms-flex-pack: start;
							justify-content: flex-start;
						}
						.blog-featured-posts__row.second ul li:first-child {
							width: 33%;
						}
						.blog-featured-posts__row.second ul li:last-child {
							margin-left: 10%;
							margin-right: 7%;
						}
						.custom-post-article .image .img-link .img-box,
						.block-custom-posts__posts
							li
							article
							.image
							.img-link
							.img-box {
							background-position: center center;
							background-repeat: no-repeat;
							background-size: cover;
							height: auto;
							padding-top: 80%;
						}
						.blog-featured-posts__row.first ul {
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							-webkit-box-pack: start;
							-ms-flex-pack: start;
							justify-content: flex-start;
						}
						.blog-featured-posts__row.first ul li:first-child {
							margin-left: 7%;
							margin-right: 10%;
						}
						.block-custom-posts__posts.columns-2 li {
							width: 50%;
						}
						.block-custom-posts__posts li {
							margin-bottom: calc(80px * var(--scale));
							padding: 0 calc(20px * var(--scale));
						}
						.custom-post-article .image .img-link,
						.block-custom-posts__posts li article .image .img-link {
							position: relative;
							display: block;
						}
						.custom-post-article .image,
						.block-custom-posts__posts li article .image {
							margin-bottom: 20px;
						}
						.custom-post-article .text .title,
						.block-custom-posts__posts li article .text .title {
							-webkit-transition: all 0.2s ease;
							-o-transition: all 0.2s ease;
							transition: all 0.2s ease;
							-webkit-transition-delay: 0s;
							-o-transition-delay: 0s;
							transition-delay: 0s;
							display: block;
							font-weight: 500;
							line-height: 1.33;
							font-size: calc(24px * var(--scale));
							text-decoration: underline;
							-webkit-text-decoration-color: transparent;
							text-decoration-color: transparent;
						}
					`}</style>

					<News title={false} showAll={false} />
				</motion.div>
			</motion.div>
			{/* <div className="blog-featured-posts">
				<div className="container">
					<div className="blog-featured-posts__row first">
						<ul className="block-custom-posts__posts fixed-img columns-2">
							<li>
								<article>
									<div className="image">
										<a
											href="https://rainmaking.io/case-study/undo-insurance/"
											className="img-link"
										>
											<div
												className="img-box"
												style={{
													paddingTop: '80%',
													backgroundImage:
														'url("https://rainmaking.io/wp-content/uploads/2020/04/tryg-post-image.jpg")',
												}}
											/>
										</a>
									</div>
									<div className="text">
										<a
											className="title"
											href="https://rainmaking.io/case-study/undo-insurance/"
										>
											Reinventing insurance from the
											ground up and acquiring 40% of the
											growing millennial segment
										</a>
										<footer className="links">
											<a
												className="link-arrow"
												href="https://rainmaking.io/case-study/undo-insurance/"
											>
												<i className="icon-arrow-tail i1" />
												<i className="icon-arrow-tail i2" />
												<i className="icon-arrow-right-tip i3" />
											</a>
										</footer>
									</div>
								</article>
							</li>

							<li>
								<article>
									<div className="image">
										<a
											href="https://rainmaking.io/case-study/ramboll-ventures/"
											className="img-link"
										>
											<div
												className="img-box"
												style={{
													paddingTop: '80%',
													backgroundImage:
														'url("https://rainmaking.io/wp-content/uploads/2020/04/ramboll-post-image.jpg")',
												}}
											/>
										</a>
									</div>
									<div className="text">
										<a
											className="title"
											href="https://rainmaking.io/case-study/ramboll-ventures/"
										>
											Launching 7 internal ventures with
											Ramboll forecasted to generate €50
											million revenue within five years of
											launch
										</a>
										<footer className="links">
											<a
												className="link-arrow"
												href="https://rainmaking.io/case-study/ramboll-ventures/"
											>
												<i className="icon-arrow-tail i1" />
												<i className="icon-arrow-tail i2" />
												<i className="icon-arrow-right-tip i3" />
											</a>
										</footer>
									</div>
								</article>
							</li>
						</ul>
					</div>

					<div className="blog-featured-posts__row second">
						<ul className="block-custom-posts__posts fixed-img columns-2">
							<li>
								<article>
									<div className="image">
										<a
											href="https://rainmaking.io/case-study/defining-the-future-flsmidth/"
											className="img-link"
										>
											<div
												className="img-box"
												style={{
													paddingTop: '80%',
													backgroundImage:
														'url("https://rainmaking.io/wp-content/uploads/2020/06/cement-600x338.jpg")',
												}}
											/>
										</a>
									</div>
									<div className="text">
										<a
											className="title"
											href="https://rainmaking.io/case-study/defining-the-future-flsmidth/"
										>
											Defining the Future of Mining &amp;
											Cement for FLSmidth
										</a>
										<footer className="links">
											<a
												className="link-arrow"
												href="https://rainmaking.io/case-study/defining-the-future-flsmidth/"
											>
												<i className="icon-arrow-tail i1" />
												<i className="icon-arrow-tail i2" />
												<i className="icon-arrow-right-tip i3" />
											</a>
										</footer>
									</div>
								</article>
							</li>
							<li>
								<article>
									<div className="image">
										<a
											href="https://rainmaking.io/case-study/gamuda-energy-efficiency/"
											className="img-link"
										>
											<div
												className="img-box"
												style={{
													paddingTop: '80%',
													backgroundImage:
														'url("https://rainmaking.io/wp-content/uploads/2020/04/gamuda-post-image.jpg")',
												}}
											/>
										</a>
									</div>
									<div className="text">
										<a
											className="title"
											href="https://rainmaking.io/case-study/gamuda-energy-efficiency/"
										>
											Driving operational efficiency for
											Malaysia’s leading infrastructure
											group
										</a>
										<footer className="links">
											<a
												className="link-arrow"
												href="https://rainmaking.io/case-study/gamuda-energy-efficiency/"
											>
												<i className="icon-arrow-tail i1" />
												<i className="icon-arrow-tail i2" />
												<i className="icon-arrow-right-tip i3" />
											</a>
										</footer>
									</div>
								</article>
							</li>
						</ul>
					</div>
				</div>
			</div>  */}

			<style jsx>{`
				* {
					color: #042825;
				}
				.header {
					padding-top: calc(240px * var(--scale));
					padding-bottom: calc(120px * var(--scale));
				}
				@media (max-width: 767px) {
					.header {
						padding-top: calc(220px * var(--scale));
						padding-bottom: calc(100px * var(--scale));
					}
				}
				.header .container .hero h1 {
					font-weight: 400;
					font-size: 10rem;
					letter-spacing: -3px;
					line-height: 1;
					padding-bottom: calc(80px * var(--scale));
					text-align: left;
				}
				@media (min-width: 992px) and (max-width: 1199px) {
					.header .container .hero h1 {
						font-size: 8.375rem;
					}
				}
				@media (min-width: 768px) and (max-width: 991px) {
					.header .container .hero h1 {
						font-size: 6rem;
					}
				}
				@media (max-width: 768px) {
					.header .container .hero h1 {
						font-size: 4.5rem;
					}
				}
				.header .container .hero h2 {
					letter-spacing: -1px;
					max-width: calc(840px * var(--scale));
				}
				.insights-wrapper {
					padding-bottom: calc(60px * var(--scale));
				}
			`}</style>
		</Layout>
	);
};

export const Head = () => (
	<>
		<title>Arbeid — Grønn Næringskompetanse</title>
		<meta name="author" content="Bjørnar Bjelland" />
		<meta name="copyright" content="Grønn Næringskompetanse" />
		<meta name="robots" content="follow" />
		<meta name="language" content="Norwegian"></meta>
		<meta name="revisit-after" content="7 days"></meta>
		<meta
			name="description"
			content="Portfolio med informasjon og bilder over tidligere arbeid vi har gjennomført."
		/>
		<meta
			name="keywords"
			content="Hva vi har gjort, tidligere arbeid, portfolio, prosjekter"
		/>
	</>
);

export default Arbeid;
